import { forwardRef } from 'react'
import styled from 'styled-components'
import classnames from 'classnames'

import React from 'react'
import { ErrorInput } from './ErrorInput'
import { ErrorProp, transformErrorProp } from './utils/errorProp'


interface InputProps extends React.ComponentPropsWithRef<'input'> {
  label: string
  name: string
  id: string
  error?: ErrorProp
}
export const Input = forwardRef<HTMLInputElement, InputProps>(({
  className,
  style,
  error: errorProp,
  ...rest
}, ref) => {
  const error = transformErrorProp(errorProp)
  return (
    <Container style={style} className={classnames(className, { error: !!error })}>
      <Label className="sr-only" htmlFor={rest.id}>{rest.label}</Label>

      <ContainerInput>
        <StyledInput 
          ref={ref} 
          {...rest} 
        />
      </ContainerInput>
      {error && <ErrorInput>{error}</ErrorInput>}
    </Container>
  )
})
Input.displayName = 'Input'

const ContainerInput = styled.div`
  width: 100%;
  display: inline-block;
  position: relative;
`

const Label = styled.label`

  &.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
  }
`

const StyledInput = styled.input`
  width: 100%;
  letter-spacing: 0;
  border: none;
  border-radius: max(25px, 1.7361112vw);
  height: max(45px, 3.125vw);
  padding-left: max(20px, 1.388888888889vw);
  padding-right: max(20px, 1.388888888889vw);
  font-size: max(16px, 1.11111112vw);
  line-height: 1em;
  background-color: ${({ theme }) => theme.fonts.fontFamilySecondary};
  font-family: ${({ theme }) => theme.fonts.fontFamilySecondary};

  /* &:focus {
    outline: none;
  } */

  ::-webkit-input-placeholder {
    /* Edge */
    color: #808080;
    font-family: ${({ theme }) => theme.fonts.fontFamilySecondary};
    letter-spacing: 0;
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #808080;
    font-family: ${({ theme }) => theme.fonts.fontFamilySecondary};
    letter-spacing: 0;
  }

  ::placeholder {
    color: #808080;
    font-family: ${({ theme }) => theme.fonts.fontFamilySecondary};
    letter-spacing: 0;
  }
`

const Container = styled.div`
  position: relative;

  &.error {
    ${ContainerInput}:before {
      content: '';
      pointer-events: none;
      display: block;
      position: absolute;
      height: 100%;
      width: 100%;
      border-radius: max(25px, 1.7361112vw);
      border: 2px solid ${({theme}) => theme.colors.error};
    }
  }
`

export default Input
