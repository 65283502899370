import { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

import { isSafari } from '../../../utils/isBrowser'

export type Source = { src: string; type: string }
interface BackgroundVideoProps {
  background?: string
  source: Source
  loop?: boolean
}
const BackgroundVideo: React.FC<BackgroundVideoProps> = ({ background, source, loop }: BackgroundVideoProps) => {
  const videoParentRef = useRef<HTMLDivElement>(null)
  const [shouldUseImage, setShouldUseImage] = useState(false)

  useEffect(() => {
    // check if user agent is safari and we have the ref to the container <div />
    if (isSafari() && videoParentRef.current) {
      // obtain reference to the video element
      const player = videoParentRef.current.children[0]

      // if the reference to video player has been obtained
      if (player instanceof HTMLVideoElement) {
        // set the video attributes using javascript as per the
        // webkit Policy
        player.controls = false
        player.playsInline = true
        player.muted = true
        player.setAttribute('muted', '') // leave no stones unturned :)
        player.autoplay = true
        player.loop = loop ? true:false

        // Let's wait for an event loop tick and be async.
        setTimeout(() => {
          // player.play() might return a promise but it's not guaranteed crossbrowser.
          const promise = player.play()
          // let's play safe to ensure that if we do have a promise
          if (promise.then) {
            promise
              // eslint-disable-next-line @typescript-eslint/no-empty-function
              .then(() => {})
              .catch(() => {
                // if promise fails, hide the video and fallback to <img> tag
                videoParentRef.current && (videoParentRef.current.style.display = 'none')
                setShouldUseImage(true)
              })
          }
        }, 0)
      }
    }
  }, [])
  return (
    <Container style={{ background: background || 'transparent' }}>
      {shouldUseImage ? (
        // TODO: This does not show the thumbnail of the video like was intended
        <Img src={source.src} alt="Muted Video" />
      ) : (
        <ContainerVideo
          ref={videoParentRef}
          dangerouslySetInnerHTML={{
            __html: loop ? `
              <video 
                autoplay 
                muted 
                loop
                playsinline
              >
                <source src="${source.src}" type="${source.type}" />
              </video>` : `
              <video 
                autoplay 
                muted 
                playsinline
              >
              <source src="${source.src}" type="${source.type}" />
            </video>
    `,
          }}
        />
      )}
    </Container>
  )
}

const Container = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
`

const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

const ContainerVideo = styled.div`
  height: 100%;
  width: 100%;

  video {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
`

export default BackgroundVideo
