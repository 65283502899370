
export type ErrorProp = string | boolean | Array<string | undefined | boolean | null>

export const transformErrorProp = (errorProp?: ErrorProp) => {
  let error: string | undefined = undefined
  if(typeof errorProp === 'string') {
    error = errorProp
  }
  if(Array.isArray(errorProp)) {
    const stringError = errorProp.filter(e => typeof e === 'string')[0]
    error = typeof stringError === 'string' ? stringError : undefined 
  }
  return error
}