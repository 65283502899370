import Link from 'next/link'
import { ReactNode } from 'react'
import styled from 'styled-components'

import { mediaMax } from '../../../constants/breakpoints'
import { ArrowRight as SVGArrowRight } from '../../Svg/ArrowRight'

interface MenuLinkProps {
  style?: React.CSSProperties
  className?: string
  href: string
  children?: ReactNode
  openInNewTab?: boolean
}
const MenuLink: React.FC<MenuLinkProps> = ({
  style, className, href, children, openInNewTab,
}: MenuLinkProps) => (
  <Link href={href} passHref>
    <StyledMenuItem style={style} className={className} target={openInNewTab ? '_blank' : ''}>
      {children}
      <StyledSVGArrowRight fill="white" />
    </StyledMenuItem>
  </Link>
)

export default MenuLink

const StyledSVGArrowRight = styled(SVGArrowRight)`
  height: max(1.6667vw, 24px);
`

const StyledMenuItem = styled.a`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  letter-spacing: 0;
  padding: 0;
  border: none;
  background: transparent;
  text-align: left;
  cursor: pointer;
  text-decoration: none;
  position: relative;

  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fonts.fontFamilyPrimary};

  
  font-size: max(24px, 1.6667vw);
  line-height: 187.5%;

  &:focus {
    outline: none;
  }

  @media ${mediaMax.tablet} {
    font-size: max(18px, 1.25vw);
    line-height: 222%;
    & > svg {
      height: 24px;
    }
  }
`
