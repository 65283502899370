import React, { ReactNode } from 'react'
import styled from 'styled-components'
import { mediaMax } from '../../constants/breakpoints'

interface SeparatorProps {
  width?: string
  title?: string
  margin?: string
  children?: ReactNode
  handleClick?: (event: React.MouseEvent) => void
  showColor?: boolean
  lineColor?: string
  showInMobile?: boolean
  isButton?: boolean
}

const MenuSeparator: React.FC<SeparatorProps> = ({
  width,
  title,
  children,
  margin,
  handleClick,
  showColor,
  lineColor,
  showInMobile,
  isButton,
}) => (
  <Wrapper width={width} margin={margin} showInMobile={showInMobile}>
    <Separator onClick={handleClick} className={isButton ? 'isbutton' : ''}>
      <SeparatorTitle>{title}</SeparatorTitle>
      <SeparatorLine showColor={showColor} lineColor={lineColor} />
    </Separator>
    {children}
  </Wrapper>
)

export default MenuSeparator

const Wrapper = styled.div<SeparatorProps>`
  width: ${({ width }) => width};
  margin-right: ${({ margin }) => margin};
  ${({ showInMobile }) => {
    if (!showInMobile) {
      return `
        @media(max-width:550px){
          display:none;
        }
      `
    }
    return `
        @media(max-width:550px){
          width:100%;
          margin-right: 0px
        }
      `
  }}
`

const Separator = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: max(22px, 1.5277777777777777vw);
  
  &.isbutton {
    cursor: pointer;
    > span {
      font-size: 20px;
    }
  }
`

const SeparatorTitle = styled.span`
  font-size: max(14px, 0.9722222222222222vw);
  margin-right: max(20px, 1.3888888888888888vw);

  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fonts.fontFamilyPrimary};
  font-weight: bold;
  letter-spacing: 0;
  text-transform: uppercase;
  white-space: nowrap;
`
interface SeparatorLineProps {
  showColor?: boolean
  lineColor?: string
}
const SeparatorLine = styled.div<SeparatorLineProps>`
  width: 100%;
  height: 2px;
  background-color: white;
  position: relative;
  ${({ showColor, lineColor }) => {
    if (showColor) {
      return `
        &:after{
          content:"";
          position:absolute;
          bottom:-2px;
          height:2px;
          width:100%;
          left:2px;
          background-color:${lineColor};
        }
      `
    }
  }}
`
