import React from 'react'
import { SVG } from '../../types/svg'

export default function MicIcon({ color, height, width } : any) {
  const fillValue = color != null ? color : '#fff'
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width != undefined ? width : '12px'} height={height != undefined ? height : '17px'} viewBox="0 0 12 17">
      <path
        fill={fillValue}
        fillRule="evenodd"
        d="M11.389 6.667c.276 0 .5.224.5.5 0 3.114-2.396 5.67-5.445 5.923v2.632c0 .276-.223.5-.5.5-.245 0-.45-.177-.491-.41l-.009-.09V13.09C2.395 12.836 0 10.281 0 7.167c0-.276.224-.5.5-.5s.5.224.5.5c0 2.73 2.214 4.944 4.944 4.944 2.731 0 4.945-2.214 4.945-4.944 0-.276.224-.5.5-.5zM5.944 0C7.51 0 8.778 1.268 8.778 2.833v4.334C8.778 8.73 7.51 10 5.944 10 4.38 10 3.111 8.73 3.111 7.167V2.833C3.111 1.268 4.38 0 5.944 0zm0 1c-1.012 0-1.833.82-1.833 1.833v4.334C4.111 8.179 4.933 9 5.944 9c1.013 0 1.834-.82 1.834-1.833V2.833C7.778 1.82 6.958 1 5.944 1z"
      />
    </svg>
  )
}

// width="24"
// height="24"
export const MicIconRatio: React.FC<SVG> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fillRule="evenodd"
        d="M12 1a4 4 0 014 4v7a4 4 0 01-8 0V5a4 4 0 014-4zm1 18.938V21h3v2H8v-2h3v-1.062A8.001 8.001 0 014 12v-2h2v2a6 6 0 0012 0v-2h2v2a8.001 8.001 0 01-7 7.938zM10 5a2 2 0 114 0v7a2 2 0 11-4 0V5z"
        clipRule="evenodd"
      ></path>
    </svg>
  )
}
