import React, { forwardRef, useState } from 'react'
import styled from 'styled-components'
import classnames from 'classnames'
import { ErrorInput } from './ErrorInput'
import { ErrorProp, transformErrorProp } from './utils/errorProp'

const CheckMark = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    viewBox="0 0 24 24"
  >
    <path
      fill="#303030"
      fillRule="evenodd"
      d="M9.707 15.293L19 6l1.414 1.414L9.707 18.121 4 12.414 5.414 11l4.293 4.293z"
      clipRule="evenodd"
    ></path>
  </svg>
)

// TODO: Accessability wanting
export interface CheckboxProps extends React.ComponentPropsWithRef<'input'> {
  label: string
  name: string
  id: string
  error?: ErrorProp
}
export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(({
  onChange,
  label,
  name,
  id,
  error: errorProp,
  className,
  ...rest
}, ref) => {
  const [checked, setChecked] = useState(false)
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if(event.target.type === 'checkbox') {
      setChecked(event.target.checked)
    }
    onChange && onChange(event)
  }

  const error = transformErrorProp(errorProp)
  return (
    <Container className={classnames('checkbox-container', { error: !!error })}>
      <Label 
        htmlFor={id} 
        className={classnames(className, { checked })}
      >
        <input
          ref={ref}
          type="checkbox"
          id={id}
          name={name}
          onChange={handleChange}
          {...rest}
        />

        <div className="box"><CheckMark /></div>
        <span className="text">{label}</span>

      </Label>
      {error && <StyledErrorInput>{error}</StyledErrorInput>}
    </Container>
  )
})
Checkbox.displayName = 'Checkbox'

const StyledErrorInput = styled(ErrorInput)`
  padding-left: max(38px,2.638888888888889vw);
  width: 100%;
`

const Container = styled.div`
  display: inline-block;
  padding-bottom:10px;

  &.error {
    .box {
      box-shadow: 0 0 10px 10px ${({theme}) => theme.colors.error};
    }
  }
`

export const Label = styled.label`
  position: relative;
  display: flex;
  /* align-items: flex-start; */
  align-items: center;
  cursor: pointer;
  font-style: normal;
  font-weight: normal;
  text-align: left;
  
  font-size: max(14px, 0.972222222vw);
  font-family: ${({ theme }) => theme.fonts.fontFamilySecondary};
  color: ${({ theme }) => theme.colors.white};

  .box {
    position: relative;
    border: 1px solid ${({ theme }) => theme.colors.white};
    border-radius: max(4px, 0.27777778vw);
    max-width: max(24px, 1.6666667vw);
    width: 100%;
    height: max(24px, 1.6666667vw);
    margin-right:  max(14px, 0.972222222vw);

    svg { 
      opacity: 0; 
      position: absolute;
    }
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  input:focus ~ .box {
    outline: -webkit-focus-ring-color auto 1px;
  }

  &.checked {
    .box {
      background-color: ${({ theme }) => theme.colors.white};
      svg { opacity: 1; }
    }
  }
`
