
import axios from 'axios'
import { GetServerSideProps } from 'next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import Head from 'next/head'
import React, { useEffect } from 'react'
import * as Sentry from '@sentry/browser'
import config from '../../config'
import { Layout } from '../common/Layout/Layout'
import { Meta } from '../common/Meta'
import { Header, HeaderButtons } from '../components/Header/Header'
import Lobby from '../components/Lobby'
import { LISAEventResolved, LISAFrontPage } from '../types/lisa'
import { checkAuth, User } from '../utils/auth'
import { getFrontPageNormalized } from '../utils/cms'
import { fetchEventsForPage } from '../utils/cms/events'
import { useEvents } from '../utils/hooks/useEvents'
import { useLiveEvents } from '../utils/hooks/useLiveEvents'

export const getServerSideProps: GetServerSideProps = async (context) => {
  const auth = await checkAuth(context.req.headers.cookie)

  const locale = (context.locale ? context.locale : context.defaultLocale) as string

  let dataLobby: LISAFrontPage
  let events: LISAEventResolved[]
  try {
    [dataLobby, events] = await Promise.all([
      getFrontPageNormalized(), 
      fetchEventsForPage(),
    ])
  }
  catch (error) {
    if (axios.isAxiosError(error)) {
      console.log(error.response?.status)
      console.log(error.response?.statusText)
    }

    //Send the error the sentry without disrupting the users experience
    Sentry.setContext('dataLobby', { 'dataLobby' : dataLobby })
    Sentry.setContext('events', { 'events' : events })
    Sentry.setContext('user', { 'user' : auth.user })
    
    Sentry.captureException(new Error('Error in server side props index.tsx'))

    return {
      props: {
        user: auth.user,
        ...await serverSideTranslations(locale, ['common']),
      },
    }
  }

  return {
    props: {
      user: auth.user,
      dataLobby,
      events,
      ...await serverSideTranslations(locale, ['common']),
    },
  }
}

interface HomeProps {
  dataLobby: LISAFrontPage
  user: User
  events: LISAEventResolved[]
}
const Home: React.FC<HomeProps> = ({
  dataLobby,
  user,
  events: eventsProp,
}: HomeProps) => {
  const isAuthenticated = !!user || config.LOGIN_TYPE === 'NONE'

  // TODO: Please better code
  useEffect(() => {
    if(isAuthenticated) { return }
    const element = document.querySelector('.social-button')
    if(element) {
      element.classList.add('active')
      element.classList.add('no-mobile')
    }
  } , [])


  const events = useEvents(eventsProp)
  const liveEvents = useLiveEvents(events)

  // un-authenticated users should not see the menu
  let pageButtons: HeaderButtons = [
    { key: 1, variant: 'contact' },
  ]
  if (isAuthenticated) {
    pageButtons = [{ key: 2, variant: 'menu' }, ...pageButtons]
  }

  return (
    <>
      <Head>
        <title>{config.PROJECT_NAME_SEO}</title>
      </Head>
      <Meta />

      <Layout>
        <Header
          buttons={pageButtons} 
          liveEvents={isAuthenticated ? liveEvents : undefined} 
          isAuthenticated={isAuthenticated}
        />
        <Lobby user={user} {...dataLobby} />
      </Layout>
    </>
  )
}

export default Home
