import { forwardRef, useState } from 'react'
import styled from 'styled-components'
import classnames from 'classnames'
import { Checkbox, CheckboxProps } from './Checkbox'

const Arrow = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M4.707 16.707l-1.414-1.414L12 6.586l8.707 8.707-1.414 1.414L12 9.414l-7.293 7.293z"
      clipRule="evenodd"
    ></path>
  </svg>
)

interface CheckboxExpandProps extends CheckboxProps {
  children?: React.ReactNode
}
export const CheckboxExpand = forwardRef<HTMLInputElement, CheckboxExpandProps>(({
  style,
  className,
  children,
  ...rest
}, ref) => {
  const [expanded, setExpanded] = useState(false)
  const handleExpandClick = () => { setExpanded(!expanded) }
  return (
    <Container 
      style={style} 
      className={classnames(className, { expanded })}
    >
      <Controls>
        <Checkbox ref={ref} {...rest} />

        <ButtonExpand onClick={handleExpandClick} type="button">
          <Arrow />
        </ButtonExpand>
      </Controls>

      <Expandable>
        {children}
      </Expandable>
    </Container>
  )
})
CheckboxExpand.displayName = 'CheckboxExpand'

const Expandable = styled.p`
  display: none;
  padding: max(10px,0.6944444vw);
  padding-left: max(38px, 2.638888888888889vw);
  padding-bottom: 0;
  font-style: normal;
  font-weight: normal;
  text-align: left;
  
  font-size: max(14px, 0.972222222vw);
  font-family: ${({ theme }) => theme.fonts.fontFamilySecondary};
  color: ${({ theme }) => theme.colors.white};
`

const ButtonExpand = styled.button`
  background: transparent;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  max-width: max(24px, 1.66666667vw);
  height: max(24px, 1.66666667vw);
  width: 100%;
  cursor: pointer;

  svg {
    transition-duration: 200ms;
    transition-property: transform;
    transform-origin: center center;
  }
`

const Controls = styled.div`
  display: flex;
  align-items: center;
  
  .checkbox-container {
    flex: 1;
  }
`

const Container = styled.div`
  width: 100%;
  border: 1px solid rgba(255, 255, 255, 0.25);
  border-width: 1px 0;
  padding: max(14px, 0.972222vw) 0;

  &.expanded {
    ${ButtonExpand} svg {
      transform: rotate3d(1,0,0,180deg);
    }

    ${Expandable} {
      display: block;
    }
  }
`