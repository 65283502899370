import React from 'react'
import styled from 'styled-components'

interface ButtonFormProps extends React.ComponentPropsWithoutRef<'button'> {
  background?: string
}
export const ButtonForm: React.FC<ButtonFormProps> = ({
  background = 'linear-gradient(91deg, #FE4D45 0%, #FE8740 100%)',
  children,
  ...rest
}) => (
  <Button {...rest} style={{ background }}>
    {children}
  </Button>
)

const Button = styled.button`
  font-style: normal;
  font-weight: bold;
  font-size: max(14px, 0.9722222222222222vw);
  font-family: ${({theme}) => theme.fonts.fontFamilySecondary};
  color: ${({theme}) => theme.colors.white};
  margin: 0;
  border: none;
  padding: max(14px, 0.9722222222222222vw);
  border-radius: max(25px, 1.7361111111111112vw);
  text-transform: uppercase;
  overflow: hidden;
  cursor: pointer;
`