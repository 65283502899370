import { SVG } from '../../types/svg'

// width="24"
// height="24"
export const ArrowRight: React.FC<SVG> = ({ ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#303030" {...props}>
    <g>
      <path
        fillRule="evenodd"
        d="M17.586 13H3v-2h14.586l-6.293-6.293 1.414-1.414L21.414 12l-8.707 8.707-1.414-1.414L17.586 13z"
        clipRule="evenodd"
      />
    </g>
  </svg>
)
