import React from 'react'
import { useTranslation } from 'next-i18next'
import config from '../../config'

/**
 * Metadata tags that stay the same between pages.
 * * Tags added with the same name get overridden.
 */
export const Meta: React.FC = () => {
  const { t } = useTranslation('common')
  return (
    <>
      <meta property="og:url" content={config.PROJECT_URL_SEO} key="ogurl" />
      <meta property="og:image" content={config.PROJECT_IMAGE_SEO} key="ogimage" /> 
      <meta property="og:site_name" content={t('seo.translatedTitle')} key="ogsitename" />
      <meta property="og:title" content={t('seo.translatedTitle')} key="ogtitle" />
      <meta property="og:description" content={t('seo.seoDescriptionHomePage')} key="ogdesc" />
      <meta name="description" content={t('seo.seoDescriptionHomePage')} /> 
      <meta name="keywords" content={t('seo.seoKeyWords')} />
    </>
  )
}