import axios from 'axios'
import classnames from 'classnames'
import { motion } from 'framer-motion'
import React, { useEffect, useRef, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import config from '../../../../config'
import { ButtonForm } from '../../../common/ButtonForm'
import { Input } from '../../../common/Input'
import { Checkbox } from '../../../common/Input/Checkbox'
import { CheckboxExpand } from '../../../common/Input/CheckboxExpand'
import { useMenuDispatch } from '../../../providers/menuProvider'
import { googleTag } from '../../../utils/googleTagManager'
import { emailPattern } from '../../../utils/validators/isEmail'
import { CheckMarkConsent as SVGCheckMarkConsent } from '../../Svg/CheckmarkConsent'
import SVGClose from '../../Svg/Close'

interface IFormInput {
  name: string
  email: string
  company: string
  terms: boolean
  willattendinperson: boolean
  wouldlikeReykjavikaccomodationinfo: boolean
}

interface RegisterFormProps {
  style?: React.CSSProperties
  className?: string
  active?: boolean
  onClose?: () => void
  onSuccess?: () => void
}
export const RegisterForm: React.FC<RegisterFormProps> = ({
  style,
  className,
  active,
  onClose,
  onSuccess,
}) => {
  const { t } = useTranslation('common')
  const [showServerError, setShowServerError] = useState(false)
  const [viewState, setViewState] = useState<'form' | 'thank-you'>('form')

  const { register, handleSubmit, formState: { errors, isSubmitting } } = useForm<IFormInput>()
  const menuDispatch = useMenuDispatch()
  const onSubmit: SubmitHandler<IFormInput> = async (data) => {
    // Reset on re submit
    setShowServerError(false)

    const LISAModel = config.LISA_modelId_registrations

    await axios.post('/api/register', {
      name: data.name,
      email: data.email.toLocaleLowerCase(),
      willattendinperson: data.willattendinperson,
      wouldlikeReykjavikaccomodationinfo: data.wouldlikeReykjavikaccomodationinfo,
      company: data.company,
      modelId: LISAModel,
    }).then(() => {
      setViewState('thank-you')
      onSuccess && onSuccess()

      // Register success we notify google tag manager
      googleTag.event({
        event: 'buttonClick',
        category: 'hugverk',
        action: 'register',
        label: 'success',
      })
    }).catch(() => {
      setShowServerError(true)

      // Register failure we notify google tag manager
      googleTag.event({
        event: 'buttonClick',
        category: 'hugverk',
        action: 'register',
        label: 'error',
      })
    })
  }

  const handleContactUsClick = () => {
    menuDispatch({ type : 'CONTACT_TOGGLE' })
  }

  useEffect(() => {
    const escFunction = (event: KeyboardEvent) => {
      if(event.key === 'Escape') {
        onClose && onClose()
      }
    }

    document.addEventListener('keydown', escFunction, false)

    return () => {
      document.removeEventListener('keydown', escFunction, false)
    }
  }, [onClose])

  // Children click propagation fires overlay click event.
  const refOverlay = useRef<HTMLDivElement>(null)
  const handleOverlayClick = (event: React.MouseEvent) => {
    if(!refOverlay.current) { return }

    if(refOverlay.current === event.target) {
      onClose && onClose()
    }
  }

  return (
    <Overlay
      style={style}
      className={classnames(className, { active })}
      animate={active ? 'open' : 'closed'}
      initial={{ opacity: 0 , pointerEvents: 'none' }}
      variants={{
        open: { opacity: 1, pointerEvents: 'auto' },
        closed: { opacity: 0, pointerEvents: 'none' },
      }}
      ref={refOverlay}
      onClick={handleOverlayClick}
    >
      <ContainerForm className="p-event-off">
        {viewState === 'form' && (
          <Form
            className="p-event-active"
            id="form-register"
            onSubmit={handleSubmit(onSubmit)}
          >
            <ButtonClose type="button" onClick={onClose}><SVGClose width="100%" /></ButtonClose>

            <Title>{t('register.register_title')}</Title>
            <RegisterDate>{t('register.register_date')}</RegisterDate>

            <Input
              {...register('name', { required: true })}
              id="register-name"
              placeholder={t('form.placeholderName')}
              className="register-input"
              label="Fullt nafn"
              error={(
                errors.name?.type === 'required' && t('form.formRequiredName')
              )}
            />

            <Input 
              {...register('email', { 
                required: true,
                pattern: emailPattern,
              })}
              id="email"
              type="email"
              placeholder={t('form.placeholderEmail')}
              className="register-input"
              label="Netfang"
              error={[
                errors.email?.type === 'required' && t('form.formRequiredEmail'),
                errors.email?.type === 'pattern' && t('form.formEmailInvalid'),
              ]}
            />

            <Input
              {...register('company', { required: true })}
              id="register-company"
              className="register-input"
              label="Fyrirtæki/stofnun"
              placeholder={t('form.placeholderCompany')}
              error={
                errors.company?.type === 'required' && t('form.formRequiredCompany')
              }
            />
           
            <Checkbox 
              {...register('willattendinperson', { required: false })}
              id="will-attend-in-person"
              label={t('form.placeholderAttendInPerson')}
            />
            

            <Checkbox 
              name="reykjavik-accomodation"
              onChange={() => register('wouldlikeReykjavikaccomodationinfo', { required: false })}
              id="reykjavik-accomodation"
              label={t('form.placeholderAccomodation')}
            />

            <CheckboxExpand 
              {...register('terms', { required: true })}
              id="register-terms"
              className="checkbox-expand"
              label={t('form.placeholderAgreement')}
              error={(
                errors.terms?.type === 'required' && t('form.formRequiredCheckBox')
              )}
            >
              {t('form.registerModal')}
            </CheckboxExpand>
          
            {showServerError && (
              <GeneralError>
                {t('generalError')}<button onClick={handleContactUsClick} type="button" className="button-inline">Hafa samband</button> 
              </GeneralError>
            )}
          
            <ButtonForm type="submit">
              {isSubmitting ? 'loading...' : t('register.register')}
            </ButtonForm>

            {/* {generalError && <GeneralErrorMobile>{generalError}</GeneralErrorMobile>} */}

            {/* <ThankYouWrapper className={thankYouActive ? 'active' : ''}>
          <ThankYouText>{translatedContent.thanksForRegistering}</ThankYouText>
          <ThankYouSmall>{translatedContent.afterRegistrationText}</ThankYouSmall>
        </ThankYouWrapper> */}
          </Form>
        )}
        {viewState === 'thank-you' && (
          <Success className="p-event-active">
            <ButtonClose type="button" onClick={onClose}><SVGClose width="100%" /></ButtonClose>
            <SVGCheckMarkConsent className="success-icon" />
            <Title>{t('form.thanksForRegistering')}</Title>
            <RegisterDate>{t('form.afterRegistrationText')}</RegisterDate>
          </Success>
        )}
      </ContainerForm>
    </Overlay>
  )
}

const ButtonClose = styled.button`
  border: 0;
  cursor: pointer;
  background: transparent;
  
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  margin: 0;
  padding: max(14px, 0.9722222222222222vw);
  border-radius: max(25px,1.7361111111111112vw);
  display: flex;
  justify-content: center;
  align-items: center;


  svg {
    width: max(26px, 1.8055555555555554vw);
    fill: ${({theme}) => theme.colors.white};
  }
`

const Success = styled.div`
  position: relative;
  width: max(375px, 26.04166668vw);
  padding: max(30px, 2.0833333vw);
  padding-top: max(42px, 2.916665vw);
  background: linear-gradient(160deg, #33517A 0%, #142E59 100%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: max(25px, 1.7361111111111112vw);

  .success-icon {
    width: 40%;
    margin: 0 auto;
    display: block;
  }
`

const GeneralError = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: max(13px, 0.9027777777777777vw);
  line-height: 133.3331%;
  border-radius: max(4px, 0.2777777777777778vw);
  
  font-family: ${({theme}) => theme.fonts.fontFamilySecondary};
  color: ${({theme}) => theme.colors.black};
  background: ${({theme}) => theme.colors.white};
  border: 4px solid ${({theme}) => theme.colors.error};
  padding: 0 max(10px, 0.6944444444444444vw);
  padding-top: max(6px, 0.4166666666666667vw);
  padding-bottom: max(8px, 0.5555555555555556vw);

  margin-bottom: max(10px, 0.6944444vw);

  .button-inline {
    border: none;
    background: none;
    margin: 0;
    padding: 0;
    font-size: inherit;
    font-weight: inherit;
    font-weight: normal;
    color: ${({theme}) => theme.colors.black};
    font-family: ${({theme}) => theme.fonts.fontFamilySecondary};
    text-decoration: underline;
    cursor: pointer;
  }
`

const RegisterDate = styled.div`
  text-align: center;
  width: 100%;
  font-size: max(1.25vw, 16px);
  font-style: italic;
  font-weight: normal;
  margin-bottom: max(40px, 2.777777777vw);
  font-family: ${({ theme }) => theme.fonts.fontFamilyPrimary};
  color: ${({ theme }) => theme.colors.white};
`

const Title = styled.h2`
  text-align: center;
  font-size: max(26px, 1.5vw);
  font-weight: normal;
  margin-bottom: max(10px, 0.6944444vw);
  width: 100%;
  font-family: ${({ theme }) => theme.fonts.fontFamilyPrimary};
  color: ${({ theme }) => theme.colors.white};
`

// We need this one for getting normal scrolling functions.
const ContainerForm = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  justify-content: center;
  align-items: center;
`

const Form = styled.form`
  position: relative;
  width: max(375px, 26.04166668vw);
  padding: max(30px, 2.0833333vw);
  padding-top: max(42px, 2.916665vw);
  background: linear-gradient(160deg, #33517A 0%, #142E59 100%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: max(25px, 1.7361111111111112vw);

  .register-input {
    margin-bottom: max(10px, 0.6944444vw);

    &.last {
      margin-bottom: max(30px, 2.083333333333333vw);
    }
  }

  .checkbox-expand {
    margin-bottom: max(30px, 2.083333333333333vw);
  }
`

// Move to separate component
const Overlay = styled(motion.div)`
  z-index: 100;
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  overflow: auto;
  background: linear-gradient(0deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
  padding: 40px 0;

  .p-event-off, .p-event-active {
    pointer-events: none;
  }

  &.active {
    .p-event-active {
      pointer-events: auto;
    }
  }
`