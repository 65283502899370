import styled from 'styled-components'

export const ErrorInput = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: max(13px, 0.9027777777777777vw);
  font-family: ${({ theme }) => theme.fonts.fontFamilySecondary};
  color: ${({ theme }) => theme.colors.error};
  width: 100%;
  padding-left: max(20px, 1.388888888889vw);
  padding-right: max(20px, 1.388888888889vw);
`